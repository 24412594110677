import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {AuthorCard, Breadcrumb, Layout, Share} from '@app/components';

import tamBirGozde from '@app/assets/images/authors/tam-bir-gozde.webp';
import sametSezen from '@app/assets/images/authors/samet-sezen.webp';
import recepCifci from '@app/assets/images/authors/recep-cifci.webp';
import mirayOzcicek from '@app/assets/images/authors/miray-ozcicek.webp';
import mitzaCagla from '@app/assets/images/authors/mitza-cagla.webp';
import ozgeAkar from '@app/assets/images/authors/ozge-akar.webp';
import baharTornaci from '@app/assets/images/authors/bahar-tornaci.webp';
import laleKemaliGonzales from '@app/assets/images/authors/lale-kemali-gonzales.webp';
import melisOzcan from '@app/assets/images/authors/melis-ozcan.webp';
import merihTombul from '@app/assets/images/authors/merih-tombul.webp';
import tayfunGuven from '@app/assets/images/authors/tayfun-guven.webp';
import guneyCinar from '@app/assets/images/authors/guney-cinar.webp';
import ismailEroglu from '@app/assets/images/authors/ismail-eroglu.webp';
import tulaySamlioglu from '@app/assets/images/authors/tulay-samlioglu.webp';
import noImageAvailable from '@app/assets/images/authors/noImageAvailable.webp';

const AUTHORS = [
  {
    name: 'Tam bir Gözde',
    profile: tamBirGozde,
  },
  {
    name: 'Samet Sezen',
    profile: sametSezen,
  },
  {
    name: 'Recep Çifçi',
    profile: recepCifci,
  },
  {
    name: 'Miray Özçiçek',
    profile: mirayOzcicek,
  },
  {
    name: 'Mitza Cagla',
    profile: mitzaCagla,
  },
  {
    name: 'Özge Akar',
    profile: ozgeAkar,
  },
  {
    name: 'Bahar Tornacı',
    profile: baharTornaci,
  },
  {
    name: 'Lale Kemali Gonzales',
    profile: laleKemaliGonzales,
  },
  {
    name: 'Melis Özcan',
    profile: melisOzcan,
  },
  {
    name: 'Merih Tombul',
    profile: merihTombul,
  },
  {
    name: 'Tayfun Güven',
    profile: tayfunGuven,
  },
  {
    name: 'Tülay Şamlıoğlu',
    profile: tulaySamlioglu,
  },
  {
    name: 'İsmail Eroğlu',
    profile: ismailEroglu,
  },
  {
    name: 'Güney Çınar',
    profile: guneyCinar,
  },
  {
    name: 'Selda Kılıç',
    profile: noImageAvailable,
  },
  {
    name: 'Taner Yalçın',
    profile: noImageAvailable,
  },
  {
    name: 'Faruk Sancak',
    profile: noImageAvailable,
  },
  {
    name: 'Berna Bayraktaroğlu',
    profile: noImageAvailable,
  },
  {
    name: 'Aslı Cimilli',
    profile: noImageAvailable,
  },
  {
    name: 'Tuncay Belen',
    profile: noImageAvailable,
  },
  {
    name: 'Ani Zorbaz',
    profile: noImageAvailable,
  },
  {
    name: 'Ayten Altun',
    profile: noImageAvailable,
  },
  {
    name: 'Seda Başkent',
    profile: noImageAvailable,
  },
  {
    name: 'Gülşah Özçakır',
    profile: noImageAvailable,
  },
  {
    name: 'Ali Gürak',
    profile: noImageAvailable,
  },
  {
    name: 'Füsun Fesli',
    profile: noImageAvailable,
  },
  {
    name: 'Müge Çevik',
    profile: noImageAvailable,
  },
  {
    name: 'Fulya Bulur',
    profile: noImageAvailable,
  },
  {
    name: 'Zeynep Bulgulu Asrar',
    profile: noImageAvailable,
  },
  {
    name: 'Berna Özer',
    profile: noImageAvailable,
  },
];

const Authors: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Authors',
  };
  const {t} = useLocale();

  return (
    <Layout authors>
      <Breadcrumb title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 py-20 lg:px-0">
        <div className="grid lg:grid-cols-3 lg:gap-x-48 gap-y-20 mt-20">
          {AUTHORS.map((author) => (
            <AuthorCard
              name={author.name}
              profile={author.profile}
              key={author.name}
            />
          ))}
        </div>
      </div>
      <div className="py-4">
        <Share title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      </div>
    </Layout>
  );
};

export default Authors;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
